@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(150px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fade-in ease 2s;
  animation-fill-mode: forwards;
}
